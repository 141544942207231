var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-card',{attrs:{"elevation":"2"}},[_c('v-card-text',[_c('h1',[_c('v-icon',{staticClass:"iconTitle"},[_vm._v("mdi-sack-percent")]),_vm._v(" Análisis de rendimientos de productores")],1),_c('br'),_c('v-layout',{attrs:{"wrap":""}},[_c('v-flex',{attrs:{"xs3":""}},[_c('v-text-field',{attrs:{"label":"Correo para recepción de avisos","outlined":""},on:{"blur":_vm.save},model:{value:(_vm.correoAvisos),callback:function ($$v) {_vm.correoAvisos=$$v},expression:"correoAvisos"}})],1),_c('v-flex',{attrs:{"xs2":""}},[_c('v-text-field',{attrs:{"label":"Límite de días","outlined":""},on:{"blur":function($event){_vm.save(); _vm.load();}},model:{value:(_vm.limiteDias),callback:function ($$v) {_vm.limiteDias=$$v},expression:"limiteDias"}})],1),_c('v-flex',{attrs:{"xs2":""}},[_c('v-text-field',{attrs:{"label":"Puntos de diferencia","outlined":""},on:{"blur":function($event){_vm.save(); _vm.load();}},model:{value:(_vm.puntosAlarma),callback:function ($$v) {_vm.puntosAlarma=$$v},expression:"puntosAlarma"}})],1),_c('v-spacer'),_c('v-flex',{attrs:{"xs2":""}},[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"autocomplete":"off","label":"Desde","readonly":"","outlined":""},model:{value:(_vm.dateFormattedInicial),callback:function ($$v) {_vm.dateFormattedInicial=$$v},expression:"dateFormattedInicial"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menuDateInicial),callback:function ($$v) {_vm.menuDateInicial=$$v},expression:"menuDateInicial"}},[_c('v-date-picker',{attrs:{"locale":"es","first-day-of-week":1,"outlined":""},on:{"change":function($event){_vm.changeDateFormatted('fechaInicial');_vm.load();}},model:{value:(_vm.dateInputInicial),callback:function ($$v) {_vm.dateInputInicial=$$v},expression:"dateInputInicial"}})],1)],1),_c('v-flex',{attrs:{"xs2":""}},[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"autocomplete":"off","label":"Hasta","readonly":"","outlined":""},model:{value:(_vm.dateFormattedFinal),callback:function ($$v) {_vm.dateFormattedFinal=$$v},expression:"dateFormattedFinal"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menuDateFinal),callback:function ($$v) {_vm.menuDateFinal=$$v},expression:"menuDateFinal"}},[_c('v-date-picker',{attrs:{"locale":"es","first-day-of-week":1,"outlined":""},on:{"change":function($event){_vm.changeDateFormatted('fechaFinal');_vm.load();}},model:{value:(_vm.dateInputFinal),callback:function ($$v) {_vm.dateInputFinal=$$v},expression:"dateInputFinal"}})],1)],1)],1),_c('v-layout',[_c('v-spacer'),_c('v-flex',{attrs:{"xs4":""}},[_c('v-autocomplete',{attrs:{"items":this.$store.state.productor,"item-text":"nombre","item-value":"id","flat":"","hide-no-data":"","label":"Filtrar por productor","prepend-inner-icon":"mdi-account-search","outlined":"","clearable":""},on:{"change":function($event){return _vm.load();}},model:{value:(_vm.selectClient),callback:function ($$v) {_vm.selectClient=$$v},expression:"selectClient"}})],1)],1)],1)],1),_c('v-layout',{staticStyle:{"margin-top":"20px"}},[_c('v-flex',{attrs:{"xs12":""}},[_c('v-data-table',{attrs:{"headers":_vm.headersResumen,"items":_vm.resumen},scopedSlots:_vm._u([{key:"item.actions",fn:function(ref){
var item = ref.item;
return [(item.estado == 'En espera')?_c('v-icon',{attrs:{"small":""},on:{"click":function($event){return _vm.estate(item.id, 'aceptado')}}},[_vm._v(" mdi-thumb-up ")]):_vm._e(),_c('v-spacer'),(item.estado == 'En espera')?_c('v-icon',{attrs:{"small":""},on:{"click":function($event){return _vm.estate(item.id, 'rechazado')}}},[_vm._v(" mdi-thumb-down ")]):_vm._e()]}}],null,true)})],1)],1),_c('showDialog',{ref:"dialog"}),_c('showMessage',{ref:"snackbar"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }