<template>
  <v-container fluid>
    <v-card elevation="2">
      <v-card-text>
        <h1><v-icon class="iconTitle">mdi-sack-percent</v-icon> Análisis de rendimientos de productores</h1><br>
        <v-layout wrap>
          <v-flex xs3>
            <v-text-field
              label="Correo para recepción de avisos"
              v-model="correoAvisos"
              outlined
              @blur="save"
            ></v-text-field>
          </v-flex>
          <v-flex xs2>
            <v-text-field
              label="Límite de días"
              v-model="limiteDias"
              outlined
              @blur="save(); load();"
            ></v-text-field>
          </v-flex>
          <v-flex xs2>
            <v-text-field
              label="Puntos de diferencia"
              v-model="puntosAlarma"
              outlined
              @blur="save(); load();"
            ></v-text-field>
          </v-flex>
          <v-spacer></v-spacer>
          <v-flex xs2>
            <v-menu
              v-model="menuDateInicial"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  autocomplete="off"
                  v-model="dateFormattedInicial"
                  label="Desde"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  outlined
                ></v-text-field>
              </template>
              <v-date-picker
                @change="changeDateFormatted('fechaInicial');load();"
                locale="es"
                :first-day-of-week="1"
                v-model="dateInputInicial"
                outlined
              ></v-date-picker>
            </v-menu>
          </v-flex>
          <v-flex xs2>
            <v-menu
              v-model="menuDateFinal"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  autocomplete="off"
                  v-model="dateFormattedFinal"
                  label="Hasta"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  outlined
                ></v-text-field>
              </template>
              <v-date-picker
                @change="changeDateFormatted('fechaFinal');load();"
                locale="es"
                :first-day-of-week="1"
                v-model="dateInputFinal"
                outlined
              ></v-date-picker>
            </v-menu>
          </v-flex>
        </v-layout>
        <v-layout>
          <v-spacer></v-spacer>
          <v-flex xs4>
            <v-autocomplete
              v-model="selectClient"
              :items="this.$store.state.productor"
              item-text="nombre"
              item-value="id"
              flat
              hide-no-data
              label="Filtrar por productor"
              prepend-inner-icon="mdi-account-search"
              outlined
              clearable
              @change="load();"
            ></v-autocomplete>
          </v-flex>
        </v-layout>
      </v-card-text>
    </v-card>
    <v-layout style="margin-top: 20px">
      <v-flex xs12>
        <v-data-table
          :headers="headersResumen"
          :items="resumen"
        >
        <template v-slot:[`item.actions`]="{ item }">
          <v-icon v-if="item.estado == 'En espera'" small @click="estate(item.id, 'aceptado')">
            mdi-thumb-up
          </v-icon>
          <v-spacer></v-spacer>
          <v-icon v-if="item.estado == 'En espera'" small @click="estate(item.id, 'rechazado')">
            mdi-thumb-down
          </v-icon>
        </template>
        </v-data-table>
      </v-flex>
    </v-layout>
    <showDialog ref="dialog" />
    <showMessage ref="snackbar" />
  </v-container>
</template>

<script>
import {BASE_URL} from '../components/constants.js'
import * as basics from '../components/basics.js'
import showMessage from '../components/Messages.vue'
import showDialog from '../components/Dialog.vue'
import genericReload from '../components/genericReload';
export default {
  name: 'Rendimientos',
  data () {
    return {
      correoAvisos: '',
      limiteDias: '',
      puntosAlarma: '',
      menuDateInicial: '',
      dateFormattedInicial: '',
      dateInputInicial: '',
      menuDateFinal: '',
      dateFormattedFinal: '',
      dateInputFinal: '',
      selectClient: null,
      headersResumen: [
        {
          text: 'Productor',
          align: 'start',
          sortable: true,
          value: 'productor',
        },
        { text: 'Fecha análisis', value: 'fecha' },
        { text: 'Nº albarán', value: 'albaran' },
        { text: 'Kilos', value: 'kilos' },
        { text: 'Rendimiento bruto', value: 'rendimientobruto' },
        { text: 'Rendimiento neto', value: 'rendimientoneto' },
        { text: 'Estado', value: 'estado' },
        { text: "", value: "actions" },
      ],
      resumen: [],
    }
  },
  components: {
    showMessage,
    showDialog,
  },
  mounted(){
    this.dateInputInicial = basics.getDate("month", -1);
    this.dateFormattedInicial = basics.formatDate(this.dateInputInicial,"dd/mm/yyyy");
    this.dateInputFinal = basics.getDate();
    this.dateFormattedFinal = basics.formatDate(this.dateInputFinal,"dd/mm/yyyy");
    this.read();
    this.load();
  },
  methods:{
    changeDateFormatted(field){
      switch(field){
        case "fechaInicial":
          if(this.dateInputInicial){
            this.dateFormattedInicial = basics.formatDate(this.dateInputInicial,"dd/mm/yyyy");
          }
          break;
        case "fechaFinal":
          if(this.dateInputFinal){
            this.dateFormattedFinal = basics.formatDate(this.dateInputFinal,"dd/mm/yyyy");
          }
          break;
      }
    },
    async read(){
      const response = await genericReload(this.$store.state.auth, `almazara`);
      if (response.status === 200) {
        const json = await response.json();
        this.limiteDias = json.body.limiteDias;
        this.puntosAlarma = json.body.limiteDiferencia;
        this.correoAvisos = json.body.emailAvisos;
      }else{
        this.$refs.snackbar.setNew(true, "ERROR. No se han podido cargar los datos de la almazara", "Cerrar", "red");
      }
    },
    async save(){
      const data = {
        limiteDias: this.limiteDias,
        limiteDiferencia: this.puntosAlarma,
        emailAvisos: this.correoAvisos,
      };

      const headers = {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer '+this.$store.state.auth
      };

      const response = await fetch(
        `${BASE_URL}/almazara`,
        {
          method: 'PUT',
          headers: headers,
          body: JSON.stringify(data),
        }
      );

      switch (response.status) {
        case 200:
          break;
        default:
          this.$refs.snackbar.setNew(true, "ERROR. Datos no guardados", "Cerrar", "red");
          break;
      }
    },
    async load(){
      this.resumen = [];
      const response = await genericReload(this.$store.state.auth, `analisisRendimiento/?fechaInicio=${this.dateInputInicial}&fechaFin=${this.dateInputFinal}`);
      if (response.status === 200) {
        const json = await response.json();
        let productor, estado, fechaEnvio, diff;
        const fechaCompara = new Date().getTime();
        for(let i = 0; i<json.body.length; i++){
          fechaEnvio = new Date(json.body[i].fecha).getTime();
          diff = (fechaCompara - fechaEnvio) / (1000*60*60*24);
          if(json.body[i].estado == "aceptado") { estado = "Aceptado"; }
          if(json.body[i].estado == "rechazado") { estado = "Rechazado"; }
          if(json.body[i].estado == "espera") { estado = "En espera"; }
          if(json.body[i].estado == "espera" && diff > this.limiteDias) { estado = "Caducado"; }
          //Calcula puntos de diferencia
          const responseAlbaran = await genericReload(this.$store.state.auth, `entrada/?idProductor=${json.body[i].idProductor}&albaran=${json.body[i].albaran}`);
          const jsonAlbaran = await responseAlbaran.json();
          if(json.body[i].estado == "espera" && Math.abs(parseFloat(jsonAlbaran.body[i].rendimiento)-parseFloat(json.body[i].rendimientoBruto)) > this.puntosAlarma) { estado = "Caducado"; }
          productor = this.$store.getters.productorById(json.body[i].idProductor);
          this.resumen.push({
            id: json.body[i].id, 
            fecha: basics.formatDate(json.body[i].fecha,"dd/mm/yyyy"), 
            productor: productor.nombre, 
            idProductor: json.body[i].idProductor, 
            albaran: json.body[i].albaran,
            kilos: json.body[i].kilos,
            estado: estado,
            rendimientobruto: json.body[i].rendimientoBruto,
            rendimientoneto: json.body[i].rendimientoNeto,
          });
        }
        this.productorFilter();
      }else{
        this.$refs.snackbar.setNew(true, "ERROR. No se han podido cargar los datos de solicitudes", "Cerrar", "red");
      }
    },
    productorFilter(){
      if(this.selectClient!=null){
        this.resumen = this.resumen.filter((line) => line.idProductor === this.selectClient);
      }
    },
    estate(id, value){
      this.$refs.dialog.setNew("Responder análisis de rendimiento", "¿Estás seguro de querer llevar a cabo esta operación? Será notificada al cliente de forma instantánea.", 0).then(async(confirm) => {
        if(confirm.response){
          const headers = {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer '+this.$store.state.auth
          };

          const data = {
            estado: value,
          };

          const response = await fetch(
            `${BASE_URL}/analisisRendimiento/${id}`,
            {
              method: 'PUT',
              headers: headers,
              body: JSON.stringify(data),
            }
          );
          switch (response.status) {
            case 200:
              this.$refs.snackbar.setNew(true, "Respuesta registrada correctamente", "Ok", "green");
              this.load();
              break;
            default:
              this.$refs.snackbar.setNew(true, "ERROR. La respuesta no ha podido ser registrada", "Cerrar", "red");
              break;
          }
        }
      });
    },
  }
}
</script>
<style scoped>
.v-input{
  margin-right: 1em;
}
</style>